<template>
  <div class="content_a">
    <div class="list" v-for="(item,index) in addressList" :key="index">
      <div @click="selctBtn(index)">
        <img v-if="item.selectShow" class="selectIcon" src="../assets/Slice39.png" />
        <div v-else class="selectBox"></div>
      </div>
      <div class="address_info" @click="selctBtn(index)">
        <div><span>{{item.receiver_name}}</span><span>{{item.receiver_mobile}}</span></div>
        <div class="address_message"><span>{{item.receiver_address}}</span><img src="../assets/Slice101.png" /></div>
        <div style="color:#E3C084" v-if="item.is_default">[默认]</div>
      </div>
      <div class="detal" @click="details(item.id)">
        <img src="../assets/Slice100.png" />
      </div>
      <div class="delete" @click="delectBtn(item.id)">
        <div>删除</div>
        <img src="../assets/Slice40.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { deleteAddress_api } from "@/api/deal";
import { Dialog } from "vant";

export default {
  data() {
    return {
      dataList: [
        {
          id: 1,
          label: "張三",
          phone: 13647139551,
          address: "武漢市江岸区金桥大道市民之家",
          selectShow: true,
          default: 1
        },
        {
          id: 1,
          label: "張三",
          phone: 13647139551,
          address: "武漢市江岸区金桥大道市民之家",
          selectShow: false,
          default: 0
        },
        {
          id: 1,
          label: "張三",
          phone: 13647139551,
          address: "武漢市江岸区金桥大道市民之家",
          selectShow: false,
          default: 0
        }
      ],
      address:''
    };
  },
  props: {
    addressList: {
      type: Array
    },
    deleteShow: {
      type: Boolean
    },

  },
  created() {
    
  },
  methods: {
    selctBtn(index) {
      this.addressList.forEach(e => (e.selectShow = false));
      this.addressList[index].selectShow = true;
      console.log(this.addressList, "21312321");
      localStorage.setItem("address", JSON.stringify(this.addressList[index]));
      this.$router.go(-1);
    },
    details(id) {
      console.log(1);
      this.$router.push(`/address/detail?id=${id}`);
    },
    delectBtn(id) {
      Dialog.confirm({
        title: "提示",
        message: "确认删除地址"
      })
        .then(() => {
          deleteAddress_api(id).then(res => {
            if (res.code == 0) {
              this.$emit("init");
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>
<style scoped>
.list {
  width: 700px;
  height: 180px;
  background: #2d2929;
  border-radius: 15px 15px 15px 15px;
  opacity: 1;
  margin: 20px auto;
  padding: 25px 44px 15px 15px;
  box-sizing: border-box;
  display: flex;
  position: relative;
}
.selectIcon {
  width: 40px;
  height: 40px;
}
.selectBox {
  width: 40px;
  height: 40px;
  opacity: 1;
  border: 2px solid #e3c084;

  border-radius: 50%;
}
.address_info {
  font-size: 30px;
  color: #fff;
  margin-left: 20px;
}
.address_info img {
  width: 22px;
  height: 26px;
  margin-left: 10px;
}
.address_info span {
  margin: 0 10px;
}
.detal {
  width: 47px;
  height: 46px;
  position: absolute;
  top: 50px;
  right: 42px;
}
.detal img {
  width: 100%;
  height: 100%;
}
.address_message {
  margin: 15px 0;
}
.address_message span {
  width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  color:#fff
}
.delete {
  font-size: 30px;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  margin-right: 20px;
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.delete img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.van-dialog {
  width: 500px;
  font-size: 30px;
}

.van-dialog__message--has-title {
  font-size: 28px;
  margin-top: 20px;
}
.van-dialog__confirm,
.van-dialog__cancel {
  font-size: 30px !important;
}
</style>

